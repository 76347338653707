import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setUpperPopup } from "../store/actions/general.actions"
const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-']

export function UpperPopup() {
    const dispatch = useDispatch()
    const { upperPopup } = useSelector((storeState) => storeState.generalModule)

    useEffect(() => {
        if (upperPopup) reset()
    }, [upperPopup])

    const reset = () => {
        setTimeout(() => { dispatch(setUpperPopup('')) }, 4000)
    }

    if (!upperPopup) return <></>

    return <section className='upper-popup'>
        {upperPopup === 'errorServer' && <p>something went wrong... please try again later.</p>}
        {upperPopup === 'errorCode' && <p>Code is incorrect, please enter a valid one.</p>}
        {upperPopup === 'statusChanged' && <p>This creator's status changed.</p>}
        {upperPopup === 'name-changed' && <p>Name changed successfully!</p>}
        {upperPopup === 'empty-content' && <p>Please fill all text fields.</p>}
        {upperPopup === 'desc-changed' && <p>Description changed successfully!</p>}
        {upperPopup === 'errorName' && <p>name must include 2-20 characters.</p>}
        {upperPopup === 'errorDesc' && <p>description must include 2-200 characters.</p>}
        {upperPopup === 'image-only' && <p>only image files are supported.</p>}
        {upperPopup === 'copied' && <p>Link Copied!</p>}
        {upperPopup === 'poll-images' && <p>You can't attach image to some answers without attaching the others as well.</p>}
        {upperPopup === 'socialError' && <p>Link broken!</p>}
        {upperPopup === 'errorCreate' && <p>something went wrong... could not create event.</p>}
        {upperPopup === 'errorDelete' && <p>something went wrong... could not delete event.</p>}
        {upperPopup === 'invalidPhone' && <p>invalid phone number, make sure the phone you entered is correct.</p>}
        {upperPopup === 'emptyField' && <p>Please fill the required fields</p>}
        {upperPopup === 'desc' && <p>Use at least 20 characters for the description.</p>}
        {upperPopup === 'otherStream' && <p>Someone else already started streaming.</p>}
        {upperPopup === 'broken-link' && <p>Please fill a valid web link.</p>}
        {upperPopup === 'comment-deleted' && <p>Comment deleted</p>}
        {upperPopup === 'email-sent' && <p>We got it!</p>}
        {upperPopup === 'price' && <p>Please enter a price between 1₪ and 1000₪</p>}
        {upperPopup === 'date' && <p>Cannot create event in the past, please enter a valid date in the future.</p>}
        {upperPopup === 'invalidPrize' && <p>Please enter a valid prize</p>}
        {upperPopup === 'errorLoadEvent' && <p>Cannot load event, please try again later</p>}
        {upperPopup === 'event-ended-error' && <p>Cannot start stream, the other creator might have ended it already</p>}
        {upperPopup === 'video-length' && <p>Video duration may not be longer than 1 minute.</p>}
        {upperPopup === 'edited' && <p>Event edited successfully!</p>}
        {upperPopup === 'video-size' && <p>500MB is the maximum video size.</p>}
        {upperPopup === 'price-change-succeed' && <p>Success! changes may take up to 48 hours to appear.</p>}
        {upperPopup === 'imgRequired' && <p>Please upload an image of the event.</p>}
        {upperPopup.includes('banned-') && <p>{upperPopup.split('-').slice(1).join('-')} is now banned.</p>}
        {upperPopup.includes('banRemoved-') && <p>Ban removed for {upperPopup.split('-').slice(1).join('-')}</p>}
        {numbers.some(num => num === upperPopup.charAt(0)) && <p>cannot start or end live stream now, event starts in {upperPopup}</p>}
    </section>
}