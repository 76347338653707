import { useEffect, useRef, useState } from 'react'
import { addBanUser, removeBanUser } from '../../store/reducers/creator.reducer'
import { setPopup, setUpperPopup } from '../../store/actions/general.actions'
import { creatorService } from '../../services/creator.service'


export function Users({ dispatch, creator }) {

    const [followers, setFollowers] = useState([])
    const [from, setFrom] = useState(0)
    const [loader, setLoader] = useState(true)
    const [isSearch, setIsSearch] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    const search = useRef(null)
    const targetRef = useRef(null)
    const debounce = useRef(false)

    useEffect(() => {
        window.addEventListener('scroll', loadMore)
        return () => window.removeEventListener('scroll', loadMore)
    }, [])

    useEffect(() => {
        loadFollowers(from)
    }, [from])

    const loadFollowers = async () => {
        try {
            const f = await creatorService.getFollowers(from)
            setFollowers(followers.concat(f))
            if (f.length < 50) setLoader(false)
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const loadMore = () => {
        if (debounce.current) return
        if (!targetRef.current) {
            window.removeEventListener('scroll', loadMore)
            return
        }
        const rect = targetRef.current.getBoundingClientRect()
        if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
            debounce.current = true
            setTimeout(() => { debounce.current = false }, 800)
            setFrom((from) => from + 50)
        }
    }

    const banUser = async (id) => {
        try {
            await creatorService.banUser(id)
            dispatch(addBanUser(id))
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const unbanUser = async (id) => {
        try {
            await creatorService.unbanUser(id)
            dispatch(removeBanUser(id))
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const searchUsers = async (e) => {
        e.preventDefault()
        try {
            const res = await creatorService.searchUsers(search.current.value)
            setSearchResults(res)
            setIsSearch(true)
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const data = isSearch ? searchResults : followers

    return <>
        <form onSubmit={searchUsers} className="search-user">
            <input ref={search} required type="text" placeholder="Search a user" />
            <div onClick={() => { setIsSearch(false); search.current.value = '' }}><span className="material-symbols-outlined">close</span></div>
            <button><span className="material-symbols-outlined">search</span></button>
        </form>

        {data.length ? <div className="followers-container">
            {data.map(f => <div key={f._id} style={{ paddingRight: '10px' }}>
                <img src={f.image} onClick={() => dispatch(setPopup(f.image))} />
                <p>{f.nickName}</p>
                {creator.bannedUsers[f._id] ? <button onClick={() => unbanUser(f._id)} style={{ background: 'green' }}>Unban</button> : <button onClick={() => banUser(f._id)}>Ban</button>}
            </div>)}
            {(loader && !isSearch) && <div style={{ margin: 'auto', position: 'relative' }}><div ref={targetRef} className="loader loader-block"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
        </div> : <p>Could not find any users...</p>}
    </>
}