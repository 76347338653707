import { useState, useRef } from "react"
import { useDispatch } from "react-redux"
import { creatorService } from "../../services/creator.service"
import { Loader } from "../loader"
import { uploadFile } from "../../services/upload.service"
import { setUpperPopup } from "../../store/actions/general.actions"
const pollImages = {
    0: null,
    1: null,
    2: null,
    3: null
}

export function Create({ addToFeed }) {
    const dispatch = useDispatch()
    const [isCreate, setIsCreate] = useState(false)
    const [content, setContent] = useState("")
    const [image, setImage] = useState(null)
    const [loader, setLoader] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null)
    const [pollOptions, setPollOptions] = useState([{ text: "", image: null }, { text: "", image: null }])
    const [video, setVideo] = useState(null)
    const imageRef = useRef(null)

    const handlePollChange = (index, field, value) => {
        const newPoll = [...pollOptions]
        newPoll[index][field] = value
        setPollOptions(newPoll)
    }

    const handlePollImageChange = async (index, ev) => {
        try {
            pollImages[index] = ev.target.files[0]
            handlePollChange(index, 'image', URL.createObjectURL(ev.target.files[0]))
        }
        catch {
            pollImages[index] = null
            handlePollChange(index, 'image', null)
        }
    }

    const handleMediaChange = (e) => {
        setVideo(e.target.files[0])
    }

    const handleAddPollOption = () => {
        if (pollOptions.length < 4) {
            setPollOptions([...pollOptions, { text: "", image: null }])
        }
    }

    const onImageChange = async (ev) => {
        try {
            if (!ev.target.files[0]) return
            if (!ev.target.files[0].type.startsWith('image/')) return dispatch(setUpperPopup('image-only'))
            setImage(URL.createObjectURL(ev.target.files[0]))
        }
        catch (err) {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const removeImageFromOptions = (ev, index) => {
        ev.stopPropagation()
        const newPoll = [...pollOptions]
        newPoll[index].image = null
        pollImages[index] = null
        setPollOptions(newPoll)
    }

    const handleSubmit = async () => {
        try {
            if (!content.trim()) {
                dispatch(setUpperPopup('empty-content'))
                return
            }
            let newFeedItem = {
                text: content,
            }

            if (selectedOption === "image" && imageRef.current.files[0]) {
                setLoader(true)
                const uploaded = await uploadFile(imageRef.current.files[0])
                newFeedItem.image = uploaded.secure_url
            }

            if (selectedOption === "poll") {
                if (pollOptions.some(option => option.text === '')) return dispatch(setUpperPopup('empty-content'))
                if (pollOptions.some(option => option.image) && pollOptions.some(option => !option.image)) return dispatch(setUpperPopup('poll-images'))
                setLoader(true)
                const promises = []
                for (let i = 0; i < pollOptions.length; i++) {
                    if (pollOptions[i]?.text) promises.push(uploadFile(pollImages[i]))
                }
                const images = await Promise.all(promises)
                newFeedItem.poll = {
                    options: pollOptions.map((option, index) => ({
                        text: option.text,
                        image: images[index].secure_url,
                        votes: 0
                    }))
                }
            }

            if (selectedOption === "video" && video) {
                if (video.size > 500000000) return dispatch(setUpperPopup('video-size'))
                setLoader(true)
                const url = await creatorService.signVideoUpload(video.name, video.type)
                const uploaded = await fetch(url, { method: 'PUT', headers: { 'Content-Type': video.type }, body: video })
                newFeedItem.video = uploaded.url.split('?')[0]
            }
            const item = await creatorService.addFeedItem(newFeedItem)
            addToFeed(item)
            setLoader(false)
            resetForm()
        }
        catch (err) {
            console.log(err)
            setLoader(false)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const resetForm = () => {
        setContent("")
        setSelectedOption(null)
        setPollOptions([{ text: "", image: null }, { text: "", image: null }]) // Reset to 2 options
        setVideo(null)
        setIsCreate(false)
    }

    if (!isCreate) return (
        <div className="create">
            <div className="create-button" onClick={() => setIsCreate(true)}>
                <span className="material-symbols-outlined">add</span>
            </div>
        </div>
    )

    return <div className="create-form">
        <textarea
            className="text-input"
            placeholder="Write your post..."
            style={{textAlign: 'right',direction: 'rtl'}}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            maxLength={4000}
        />

        <div className="options">
            <button
                className={`option-button ${selectedOption === "image" ? "selected" : ""}`}
                onClick={() => setSelectedOption(selectedOption === "image" ? null : "image")}
            >
                Add Image
            </button>

            <button
                className={`option-button ${selectedOption === "video" ? "selected" : ""}`}
                onClick={() => setSelectedOption(selectedOption === "video" ? null : "video")}
            >
                Add Video
            </button>

            <button
                className={`option-button ${selectedOption === "poll" ? "selected" : ""}`}
                onClick={() => setSelectedOption(selectedOption === "poll" ? null : "poll")}
            >
                Add Poll
            </button>
        </div>

        {selectedOption === "image" && <div className='img-wrapper'>
            <input type="file" ref={imageRef} className="non-appear" accept="image/png, image/jpeg, img/webp, img/avix" id='image-feed' onChange={(e) => onImageChange(e, 'image')} />
            <label htmlFor='image-feed' className="feed-image">
                {image ? <img src={image} /> :
                    <span className="material-symbols-outlined">add_a_photo</span>}
            </label>
        </div>}

        {selectedOption === "video" && <div className='img-wrapper'>
            <input type="file" className="non-appear" accept="video/*" id='video-feed' onChange={handleMediaChange} />
            <label htmlFor='video-feed' className="feed-image">

                <span className="material-symbols-outlined">{video ? 'check' : 'upload_file'}</span>
            </label>
        </div>}

        {selectedOption === "poll" && (
            <div className="poll-options">
                {pollOptions.map((option, index) => (
                    <div key={index} className="poll-option">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handlePollImageChange(index, e)}
                            className="non-appear"
                            id={index}
                        />
                        <label htmlFor={index}>
                            {option.image ? <>
                                <span className="material-symbols-outlined remove-img" onClick={(e) => removeImageFromOptions(e, index)}>close</span>
                                <img src={option.image} />
                            </> :
                                <span className="material-symbols-outlined">add_a_photo</span>}
                        </label>
                        <input
                            type="text"
                            className="poll-input"
                            placeholder={`Option ${index + 1}`}
                            value={option.text}
                            onChange={(e) => handlePollChange(index, 'text', e.target.value)}
                            maxLength={50}
                        />
                    </div>
                ))}
                {pollOptions.length > 2 && (
                    <button className="remove-option" onClick={() => setPollOptions(pollOptions.slice(0, -1))}>
                        Remove option
                    </button>
                )}
                {pollOptions.length < 4 && (
                    <button className="add-option" onClick={handleAddPollOption}>
                        Add option
                    </button>
                )}
            </div>
        )}
        <div className="submit-section">
            {loader ? <Loader /> : <>
                <button className="cancel-button" onClick={resetForm}>Cancel </button>
                <button className="submit-button" onClick={handleSubmit}>Post</button>
            </>}
        </div>
    </div>
}
