import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    _id: '',
    phone: '',
    nickName: '',
    description: '',
    subscription: {},
    image: '',
    socialLinks: '',
    banner: '',
    shows: [],
    status: '',
    followersAmount: 0,
    bannedUsers: {}
}

export const userSlice = createSlice({
    name: 'creator',
    initialState,
    reducers: {
        setPhone: (state, action) => {
            state.phone = action.payload
        },
        setNickName: (state, action) => {
            state.nickName = action.payload
        },
        setImage: (state, action) => {
            state.image = action.payload
        },
        setSocialLinks: (state, action) => {
            state.socialLinks = action.payload
        },
        setDescription: (state, action) => {
            state.description = action.payload
        },
        setBanner: (state, action) => {
            state.banner = action.payload
        },
        setSubscription: (state, action) => {
            state.subscription = { ...state.subscription, ...action.payload }
        },
        addBanUser: (state, action) => {
            state.bannedUsers = { ...state.bannedUsers, [action.payload]: true }
        },
        removeBanUser: (state, action) => {
            const b = state.bannedUsers
            delete b[action.payload]
            state.bannedUsers = b
        },
        addShow: (state, action) => {
            state.shows = { ...state.shows, [action.payload._id]: action.payload }
        },
        endShow: (state, action) => {
            const shows = state.shows.map(show => show._id === action.payload ? { ...show, status: 'over' } : show)
            state.shows = shows
        },
        login: (state, action) => {
            state._id = action.payload._id
            state.phone = action.payload.phone
            state.nickName = action.payload.nickName
            state.image = action.payload.image
            state.description = action.payload.description
            state.socialLink = action.payload.socialLink
            state.banner = action.payload.banner
            state.shows = action.payload.shows
            state.status = action.payload.status
            state.followersAmount = action.payload.followersAmount
            state.subscription = action.payload.subscription
        },
        logout: (state) => {
            state.phone = ''
            state.nickName = ''
            state.image = ''
            state.socialLink = ''
            state.banner = ''
            state.description = ''
            state.shows = []
            state.status = ''
            state.followersAmount = 0
            state.bannedUsers = {}
            state.subscription = {}
            document.cookie = "authCreator=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
            document.cookie = "loginToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
        }
    }
})

export const {
    setPhone,
    setNickName,
    setImage,
    setSocialLinks,
    setDescription,
    addBanUser,
    removeBanUser,
    endShow,
    setSubscription,
    addShow,
    setBanner,
    login,
    logout
} = userSlice.actions

export default userSlice.reducer
