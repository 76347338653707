import { useState, useRef } from "react"
import { useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { io } from "socket.io-client"
import { setUpperPopup } from '../store/actions/general.actions'
import { isMobile } from "react-device-detect"
let socket = null
const colors = ["blue", "cyan", "magenta", "lime", "maroon", "navy", "olive", "teal", "violet", "silver", "gold", "indigo", "coral", "crimson", "fuchsia", "khaki", "lavender", "plum", "turquoise", "wheat", "beige", "azure", "aliceblue", "antiquewhite", "aquamarine", "bisque", "blanchedalmond", "blueviolet", "burlywood", "cadetblue", "chartreuse", "chocolate", "cornflowerblue", "cornsilk", "darkblue", "darkcyan", "darkgoldenrod", "darkgray", "darkgreen", "darkgrey", "darkkhaki", "darkmagenta", "darkolivegreen", "darkorange", "darkorchid", "darkred", "darksalmon", "darkseagreen", "darkslateblue", "darkslategray", "darkslategrey", "darkturquoise", "darkviolet", "deeppink", "deepskyblue", "dimgray", "dimgrey", "dodgerblue", "firebrick", "floralwhite", "forestgreen", "gainsboro", "ghostwhite", "gold", "goldenrod", "greenyellow", "honeydew", "hotpink", "indianred", "ivory", "lavenderblush", "lawngreen", "lemonchiffon", "lightblue", "lightcoral", "lightcyan", "lightgoldenrodyellow", "lightgray", "lightgreen", "lightgrey", "lightpink", "lightsalmon", "lightseagreen", "lightskyblue", "lightslategray", "lightslategrey", "lightsteelblue", "lightyellow", "limegreen", "linen", "mediumaquamarine", "mediumblue", "mediumorchid", "mediumpurple", "mediumseagreen", "mediumslateblue", "mediumspringgreen", "mediumturquoise", "mediumvioletred", "midnightblue", "mintcream", "mistyrose", "moccasin", "navajowhite", "oldlace", "ol"]

export function StreamChat({ eventId, zIndex, start, setViewers }) {
    const dispatch = useDispatch()
    const creator = useSelector(state => state.creator)
    const [messages, setMessages] = useState([])
    const [menu, setMenu] = useState('')
    const [name, setName] = useState('')
    const [position, setPosition] = useState(0)
    const timeRef = useRef()

    let nickName = (creator && creator.nickName) ? creator.nickName : "Guest" + Math.floor(Math.random() * 10000)
    let randomColor = colors[nickName.charCodeAt(0) % colors.length] || 'white'

    useEffect(() => {
        socket = process.env.NODE_ENV === 'production' ? io('https://chat.pikme.tv', { transports: ['websocket'] }) : io('http://localhost:3031', { transports: ['websocket'] })

        if (socket && creator) {

            socket.emit('joinRoom', { username: creator.nickName, roomId: eventId, userId: creator._id })

            socket.on('message-bulk', (m) => setMessages(m))

            socket.on('message', (message) => {
                setMessages((prevMessages) => [...prevMessages, message])
            })

            socket.on('viewers-update', (v) => setViewers(v))
        }
        return () => {
            if (!socket) return
            socket.off("message")
            socket.off("message-bulk")
            socket.off("viewers-update")
            socket.emit('leaveRoom', eventId)
            socket.close()
            socket = null
        }
    }, [])

    useEffect(() => {
        document.getElementById('body-text').scrollTop = document.getElementById('body-text').scrollHeight
    }, [messages])

    useEffect(() => {
        if (start) socket.emit('start-stream', eventId)
    }, [start])

    const sendMessage = () => {
        const text = document.getElementById('input').value
        if (text === "") return
        if (socket) socket.emit('chat', { nickName, text, color: randomColor, roomId: eventId, image: creator.image, userId: creator._id })
        document.getElementById('input').value = ''
        document.getElementById('body-text').scrollTop = document.getElementById('body-text').scrollHeight
    }

    const prevent = (e) => {
        e.preventDefault()
    }

    const onEnter = (ev) => {
        if (ev.key === "Enter") sendMessage()
    }
    const muteUser = () => {
        socket.emit('mute', { userId: menu, time: timeRef.current.value, adminPhone: creator.phone })
        dispatch(setUpperPopup('muted'))
        setMenu('')
    }

    const openMenu = (menu, name, el) => {
        setMenu(menu)
        setName(name)
        const rect = el.getBoundingClientRect()
        const top = rect.top + window.scrollY - 30
        if (top + 300 > window.innerHeight) setPosition((top - 200) + 'px')
        else setPosition(top + 'px')
    }

    return <div className="chat-box" style={{ zIndex }}>
        <div className="chat-header">
            <h1>Live chat</h1>
        </div>

        <div id="body-text" className="body-text" >
            {messages.map((message, index) => <p className="message-text" key={index} onClick={(ev) => openMenu(message.userId, message.nickName, ev.target)}>
                <span style={{ color: message.color }}>{message.nickName} : </span>
                <span style={{ marginLeft: "5px", fontWeight: "lighter",wordBreak:'break-all' }} >{message.text}</span>
            </p>)}
        </div>

        {menu && <div className="ban-menu" style={{ top: isMobile ? '20%' : position || '40%' }}>
            <div className="name">{name}</div>
            <div className="selects">
                <p>Mute : </p>
                <select ref={timeRef}>
                    <option value="60">1 minute</option>
                    <option value="600">10 minutes</option>
                    <option value="1800">30 minutes</option>
                    <option value="3600">1 hour</option>
                    <option value="10800">3 hours</option>
                    <option value="86400">1 day</option>
                    <option value="604800">1 week</option>
                </select>
            </div>
            <div className="buttons">
                <div onClick={muteUser}>Confirm</div>
                <div onClick={() => setMenu('')}>Cancel</div>
            </div>
        </div>}

        <form className="chat-box-input" onSubmit={prevent}>
            <input id="input" type="text" onKeyDown={onEnter} placeholder="Type a message" />
            <input type='submit' style={{ width: '0', padding: '0', borderWidth: '0', visibility: 'hidden' }} />
            <button style={{ width: '0', padding: '0', borderWidth: '0', visibility: 'hidden' }}></button>
        </form>
    </div>
}